import React from "react";
import THAILAND_IMG from "../images/thailand.png";
import MALAYSIA_IMG from "../images/malaysia.png";

const FLAGS = {
    Thailand: THAILAND_IMG,
    Malaysia: MALAYSIA_IMG,
  };


const Flags = ({ data }) => {
  return (
    <div className="flags">
      {data.map((country, index) => 
        FLAGS[country] ? (
          <img key={index} src={FLAGS[country]} alt={country} />
        ) : null
      )}
    </div>
  );
};

export default Flags;
