import styled from "styled-components";

export const Wrapper = styled.div`
  .project-detail {
    position: relative;
    padding-top: 40px;
    margin-top: 40px;
    .top {
      button {
        min-width: 180px;
      }
      h2 {
        margin-bottom: 5px;
        .domain {
          position: relative;
          top: 5px;
          margin-left: 10px;
        }
      }
      .status {
        svg {
          width: 25px;
          color: #fff;
        }
        min-width: auto;
        position: relative;
        padding: 15px 5px;
        border-radius: 5px;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        text-transform: none;
        &.completed {
          background: rgb(67, 185, 178);
        }
        &.implementing {
          background: #276fbf;
        }
        &.cancelled {
          background: #ff4747;
        }
      }
    }
    p {
      &.tpm {
        color: #0071cd;
        span {
          margin-left: 20px;
        }
        svg {
          margin-right: 5px;
          position: relative;
          top: 5px;
          width: 18px;
        }
      }
    }
    .top-section {
      position: relative;
      h2 {
        img {
          width: 30px;
          position: relative;
          top: 0;
          margin-left: 10px;
        }
      }
      .edit-project {
        position: absolute;
        right: 0;
        top: 40px;
      }
    }

    .jira-description {
      height: 100%;
      max-height: 150px;
      overflow-y: scroll;
    }

    .detail-container {
      .MuiTableCell-root {
        border: 1px solid rgba(224, 224, 224, 1);
      }
      .timeline-container {
        background: #f1f1f1;
        padding: 30px;
        margin: 20px 0 40px;
      }
      h3 {
        font-size: 18px;
        span {
          position: relative;
          top: -8px;
          float: right;
          font-weight: 400;
          font-size: 14px;
          letter-spacing: 0;
          color: #0071cd;
          svg {
            position: relative;
            top: 7px;
            width: 25px;
            margin-right: 2px;
          }
        }
      }
      .summary-item {
        min-height: 235px;
        h4 {
          svg {
            margin-right: 5px;
          }
          &.light {
            font-weight: 300;
          }
        }
      }
      .detail-item {
        &.team-summary {
          table {
            .right {
              text-align: right;
            }
            th {
              padding: 10px 15px;
              border-bottom: 0;
            }
            td {
              border-bottom: 0;
              padding: 5px 15px !important;
              &.bar {
                width: 30%;
                p {
                  &.percent {
                    font-size: 14px;
                    position: relative;
                    top: 7px;
                  }
                }
              }
            }
            a {
              color: #000;
              &:hover {
                color: rgb(0, 188, 180);
              }
            }
            span {
              padding-left: 5px;
            }
          }
        }
        p {
          line-height: 125%;
          margin-bottom: 15px;
        }
        .item {
          position: relative;
          .center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 50%;
          }
          &.border {
            border: 1px solid rgb(227, 227, 227);
          }
        }
      }
    }
  }
`;
