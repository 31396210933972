import React from "react";

const JiraDescription = ({ data }) => {
  const renderContent = (content) => {
    return content?.map((block, index) => {
      switch (block.type) {
        case "paragraph":
          return (
            <p key={index}>
              {block.content?.map((item, subIndex) => {
                if (item.type === "text") {
                  return item.marks?.some((mark) => mark.type === "strong") ? (
                    <strong key={subIndex}>{item.text}</strong>
                  ) : (
                    <span key={subIndex}>{item.text}</span>
                  );
                }
                return null;
              })}
            </p>
          );

        case "orderedList":
          return <ol key={index}>{renderContent(block.content)}</ol>;

        case "bulletList":
          return <ul key={index}>{renderContent(block.content)}</ul>;

        case "listItem":
          return <li key={index}>{renderContent(block.content)}</li>;

        case "panel":
          return (
            <div key={index} className={`panel ${block.attrs.panelType}`}>
              {renderContent(block.content)}
            </div>
          );

        default:
          return null;
      }
    });
  };

  return <div className="jira-description">{renderContent(data)}</div>;
};

export default JiraDescription;
